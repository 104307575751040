import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

// import Card from './Card';




export default class Dashboard extends Component {
    render() {
        return (
            <>
                <Sidebar />

                <div className="main">
                    <div className="topbar">
                        <Navbar/>
                    </div>
                    <div>
                    <h1>Welcome to Dashboard!!!</h1>
                    </div>
                    {/* <Card /> */}
               
                </div>



            </>
        )
    }
}
