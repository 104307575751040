import React, { Component } from "react"
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import SearchLocationInput from "./SearchLocationInput";


export default class Addpolicestationuser extends Component {

  constructor(pops) {
    super(pops);

    this.state = {
      policeStationId:localStorage.getItem("StationId"),
    
      firstName: "",
      lastName: "",
      mobile: "",
      emailId: "",
      policeRole: "",
      address: "",
      password: "",
      dob: "",
      YearOfBirth: "",
      gender: "",
      city: "",
      state: "",

    }


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {

    this.setState({
      [event.target.name]: event.target.value

    });
  }

  handleSubmit(event) {

    event.preventDefault();
    // if (this.handleFormValidation()) {
    const PoliceStationUser = {
    policeStationId:this.state.policeStationId,
      ...this.state

    }
    


    axios.post("https://server.sps.foxberry.link/v1/policestationuser/insertpolicestationuser", PoliceStationUser)
      .then(res => {
        console.log("add station response", res);
        console.log("add station response", res.data);


        if (res.status === 200) {

          alert('PoliceStation User add successfully.')

          //   statesSet.setState({
          //     changeToView: true,
          //   })
        }
      })
  }


  render() {
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />

          </div>
          <div className="container">

            <div className="card rounded shadow shadow-sm  m-4">
              <div className="card-header">
                <h3 className="mb-0 center">Add policestation User</h3>

              </div>
              <div className="card-body">
                <form id="Addstation" onSubmit={this.handleSubmit}>


                  <div className="form-group">
                    <label htmlFor="firstName" className="control-label">First Name</label>
                    <input type="text" id="firstName" name="firstName" className="form-control rounded-1" value={this.state.firstName} onChange={this.handleChange} placeholder="Enter your name" required={true} />
                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="lastName" className="control-label">Last Name</label>
                    <input type="text" id="lastName" name="lastName" className="form-control rounded-1" value={this.state.lastName} onChange={this.handleChange} placeholder="Enter your name" required={true} />

                  </div>

                  <div className="form-group mt-4" >
                    <label htmlFor="mobile" className="control-label">Contact Number</label>
                    <input type="text" id="mobile" name="mobile" className="form-control rounded-1" value={this.state.mobile} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div>

                  <div className="form-group mt-4" >
                    <label htmlFor="emailId" className="control-label">Email Id</label>
                    <input type="text" id="emailId" name="emailId" className="form-control rounded-1" value={this.state.emailId} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="policeRole" className="control-label">Police Role</label>
                    <input type="text" id="policeRole" name="policeRole" className="form-control rounded-1" value={this.state.policeRole} onChange={this.handleChange} placeholder="Enter your name" required={true} />

                  </div>
  
                  <div className="form-group mt-4" >
                    <label htmlFor="address" className="control-label">Address</label>
                    {/* <input type="text" id="address" name="address" className="form-control rounded-1" value={this.state.address} onChange={this.handleChange} placeholder="Enter your name" required={true} /> */}
                    <SearchLocationInput name="address" value={this.state.address} onchange={this.handleChange}
                      callback={(addr) => {

                        const lat = addr.geometry.location.lat();
                        const long = addr.geometry.location.lng();

                        this.setState({
                          address: addr.formatted_address,
                          full_addr: addr,
                          lat, long
                        });
                      }}
                    />
                  </div>
 
                  <div className="form-group mt-4" >
                    <label htmlFor="city" className="control-label">City</label>
                    <input type="text" id="city" name="city" className="form-control rounded-1" value={this.state.city} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div> 

                   
                  <div className="form-group mt-4" >
                    <label htmlFor="state" className="control-label">State</label>
                    <input type="text" id="state" name="state" className="form-control rounded-1" value={this.state.state} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div>

                  <div className="form-group mt-4" >
                    <label htmlFor="password" className="control-label">Password</label>
                    <input type="text" id="password" name="password" className="form-control rounded-1" value={this.state.password} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div>
                  
              

                  
                  <div className="form-group mt-4" >
                    <label htmlFor="dob" className="control-label">Date Of Birth</label>
                    <input type="text" id="dob" name="dob" className="form-control rounded-1" value={this.state.dob} onChange={this.handleChange} placeholder="Enter Birth date" required={true} />

                  </div>

           
                  <div className="form-group mt-4" >
                    <label htmlFor="YearOfBirth" className="control-label">Year Of Birth</label>
                    <input type="text" id="YearOfBirth" name="YearOfBirth" className="form-control rounded-1" value={this.state.YearOfBirth} onChange={this.handleChange} placeholder="Enter Birth of Year" required={true} />

                  </div>
                  
                  <div className="form-group mt-4" >
                    <label htmlFor="gender" className="control-label">Gender</label>
                    <input type="text" id="gender" name="gender" className="form-control rounded-1" value={this.state.gender} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div>



                  <div class="row justify-content-center mt-4">
                    <button type="submit" class="btn btn-default btn-primary" style={{ width: "120px", padding: "10px" }}>submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

