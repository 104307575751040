import React, { Component } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

//import { Edit, Delete, Add,Visibility } from '@material-ui/icons';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

export default class Policestationlist extends Component {

    constructor(props) {
        super(props);
        this.state = {
        
            stationName: "",
            address: "",
            contactNo: "",
            telephoneNo: "",
            emailId: "",
            registartionNo: "",
            lat: "",
            long: "",
            stationId: "",
            policestaions:[],
            isChecked: false,
        };
    this.getData = this.getData.bind(this);
 
    }
    UNSAFE_componentWillMount() {
        this.getData();
    }
    getData() {
        let currentComponent = this;
        axios.get(`https://server.sps.foxberry.link/v1/policestation/getallpolicestation/`)
            .then(res => {
                console.log("pagination data", res);
                let responses = res.data;
                console.log("pagination data responses.response --- ", responses);
                currentComponent.setState({
                    policestaions:responses,
                });

            });
    }


       
    handleSubmit(event) {

        event.preventDefault();
        // if (this.handleFormValidation()) {
    const PoliceStation = {
  ...this.state
    }
      
      
        axios.put("https://server.sps.foxberry.link/v1/policestation/deletepolicestation", PoliceStation)
          .then(res => {
            console.log("delete station response", res);
            console.log("delete station response", res.data);
            if (res.status === 200) {
              alert(' delete POlice-station successfully.')
              // statesSet.setState({
              //   changeToView: true,
              // })
            }
          })
      } 
  
    render() {

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="text-center"> PoliceStation List </h2>
                                <br></br>
                            </div>

                          

                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>stationName</th>
                                                <th>address</th>
                                                <th>contactNo</th>
                                                <th>emailId</th>
                                                <th>registartionNo</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.policestaions.map((policestation, index) => (
                                                <tr key={policestation._id} >

                                                    <td>{index + 1}</td>
                                                    <td>{policestation.stationName}</td>
                                                    <td>{policestation.address}</td>
                                                    <td>{policestation.contactNo}</td>
                                                    <td>{policestation.emailId}</td>
                                                    <td>{policestation.registartionNo}</td>
                                                    
                                                    {/* <td>
                                                        <Link to={`/Edit-PoliceStation?id=${policestation.stationId}`} ><Edit/></Link>
                                                    </td> */}
                                                  <td>
                                                    <Link onClick={() => localStorage.setItem('stationId',policestation.stationId)} to="/Edit-PoliceStation"><button type="button" className="btn btn-success btn-sm m-1">EDIT</button></Link>
                                                    </td>

                                                    <td>
                                                    <Link onClick={() => localStorage.setItem('stationId',policestation.stationId)} ><button type="button" className="btn btn-success btn-sm m-1">Delete</button></Link>
                                                    </td>

                                                </tr>
                                                

                                            ))}

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



