import React, { Component } from "react"
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

export default class Editpolicestation extends Component {

    constructor(pops) {
        super(pops);
    
        this.state = {
         stationName: "",
         address: "",
         contactNo: "",
         telephoneNo: "",
         emailId:"",
         registartionNo:"",
         stationId:"",
         lat:"",
         long:"",
       

        }


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {

        this.setState({
          [event.target.name]: event.target.value
    
        });
      }
      
    UNSAFE_componentWillMount() {
        this.getData();
  
      }
      getData() {
        let currentComponent = this;
        let stationId = localStorage.getItem("stationId");
      
        axios.post("https://server.sps.foxberry.link/v1/policestation/getpolicestation/",{stationId})
          .then(res => {
            console.log("pagination data", res);
            let responses = res.data;
            console.log("------res --- ", responses);
    
            currentComponent.setState({
              ...responses[0]
            });

        });
    }
        
    
    handleSubmit(event) {

        event.preventDefault();
        // if (this.handleFormValidation()) {
    const PoliceStation = {
  ...this.state
    }
      
      
        axios.put("https://server.sps.foxberry.link/v1/policestation/updatepolicestation", PoliceStation)
          .then(res => {
            console.log("add station response", res);
            console.log("add station response", res.data);
            if (res.status === 200) {
              alert(' Update POlice-station successfully.')
              // statesSet.setState({
              //   changeToView: true,
              // })
            }
          })
      } 
  


        render() {
    return (
        <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />

          </div>
          <div className="container">
          {/* <BackAndBreadCrmOneParent backLink="/candidateList" backName="Candidate List" currentTabName="Add new candidate"/> */}
          <div classname="card rounded shadow shadow-sm  m-4">
    <div classname="card-header">
        <h3 className="mb-0 center">Edit Policestation</h3>

    </div>
                  <div className="card-body">
                    <form id="Addstation" onSubmit={this.handleSubmit}>


                    <div className="form-group mt-4">
                        <label htmlFor="registartionNo" className="control-label">Registration No</label>
                        <input type="text" id="registartionNo" name="registartionNo" className="form-control rounded-1" value={this.state.registartionNo} onChange={this.handleChange} placeholder="Enter Registartion Number" />
                      </div>

                    <div class="form-group mt-4">
                        <label htmlFor="stationName" className="control-label">Station Name</label>
                        <input type="text" id="stationName" name="stationName" className="form-control rounded-1"  value={this.state.stationName} onChange={this.handleChange} placeholder="Enter Station Name"  />
                     
                      </div> 


                      <div className="form-group mt-4" >
                        <label htmlFor="address" className="control-label">Address</label>
                        <input type="text" id="address" name="address" className="form-control rounded-1" value={this.state.address} onChange={this.handleChange} placeholder="Enter Address"  />
                      
                      </div>
  
                      <div className="form-group mt-4" >
                        <label htmlFor="lat" className="control-label">Latitude</label>
                        <input type="text" id="lat" name="lat" className="form-control rounded-1" value={this.state.lat} onChange={this.handleChange} placeholder="Enter Address" />
                      
                      </div>
                    
                      <div className="form-group mt-4" >
                        <label htmlFor="long" className="control-label">longitude</label>
                        <input type="text" id="long" name="long" className="form-control rounded-1" value={this.state.long} onChange={this.handleChange} placeholder="Enter Address"  />
                      
                      </div>


                      <div className="form-group mt-4">
                        <label htmlFor="contactNo" className="control-label">Contact Number</label>
                        <input type="text" id="contactNo" name="contactNo" className="form-control rounded-1" value={this.state.contactNo} onChange={this.handleChange} placeholder="Enter your Contact Number"  />
                    
                      </div>

                      <div className="form-group mt-4">
                        <label htmlFor="telephoneNo" className="control-label">Telephone Number</label>
                        <input type="text" id="telephoneNo" name="telephoneNo" className="form-control rounded-1" value={this.state.telephoneNo} onChange={this.handleChange} placeholder="Enter Telephone Number"/>
                     
                      </div>
                       
                      <div className="form-group mt-4 ">
                        <label htmlFor="emailId" className="control-label">Email Id</label>
                        <input type="text" id="emailId" name="emailId" className="form-control rounded-1" value={this.state.emailId} onChange={this.handleChange} placeholder="Enter Email ID"  />
                     
                      </div>

                
                      <div class="row justify-content-center mt-4">
                                        <button type="submit"   class="btn btn-default btn-primary" style={{ width: "120px", padding: "10px" }}>Update</button>
                                    </div>
              
                    </form>
                </div>
              </div>
            </div>
          </div>
      
      </>
    )
  }
}


