import React, { Component } from 'react';
//import logo from "../Assest/CSS/Images/apglobale.png";
import '../Assests/CSS/Dashboardstyle.css';

export default class Navbar extends Component {
    render() {
        function toggleMenu() {
            let toggle = document.querySelector('.toggle');
            let navigation = document.querySelector('.navigation');
            let main = document.querySelector('.main');
            toggle.classList.toggle('active');
            navigation.classList.toggle('active');
            main.classList.toggle('active');
        }
        return (
            <div className="container">

                <div className="topbar">
                    <div className="toggle" onClick={toggleMenu}></div>
                    {/* <div className="search">
                        <label>
                            <input type="text" placeholder="Search here" />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </label>

                    </div> */}
                    {/* <div className="user">
                        <img src={logo} alt="apg logo" width="120px" height="30px" />
                    </div> */}
                </div>
            </div>
        )
    }
}
