import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from "./Dashboard";
import Addpolicestation from "./Addpolicestation";
import Editpolicestation from "./Editpolicestation";
import Addpolicestationuser from "./Addpolicestationuser";
import Policestationlist from "./Policestationlist";



class Routes extends React.Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/Add-PoliceStation" component={Addpolicestation} />
                    <Route path="/Edit-PoliceStation" component={Editpolicestation} />
                    <Route path="/Add-PoliceStationUser" component={Addpolicestationuser}/>
                    <Route path="/List-policestation" component={Policestationlist}/>
               
                    </Switch>
                </Router>
            </div>


        );
    }
}
export default Routes;
