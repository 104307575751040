import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import '../Assests/CSS/Dashboardstyle.css';
import '../Assests/CSS/style.css';


export default class Sidebar extends Component {
    render() {
        return (
            <>
            <div className="containers">
                <div className="navigation">
                    
                  <ul>
                        {/* <div className="divider-10" >
                            <img src={logo} alt="yinimages" width="120px" height="30px"  />
                        </div> */}
                        <li className="divider-20">
                            <Link to="/dashboard">
                                <span className="icon"><i className="fa fa-home"></i></span>
                                <span className="title">Dashboard</span>
                            </Link>
                        </li>
                        <li className="divider-10">
                            <Link to="/Add-PoliceStation">
                                <span className="icon"><i className="fa fa-list"></i></span>
                                <span className="title">Add Police Station</span>
                            </Link>
                        </li>
                       
                        <li className="divider-10">
                            <Link to="/Edit-PoliceStation">
                                <span className="icon"><i className="fa fa-list"></i></span>
                                <span className="title">Edit policestation Data</span>
                            </Link>
                        </li>

                        <li className="divider-10">
                            <Link to="/List-policestation">
                                <span className="icon"><i className="fa fa-list"></i></span>
                                <span className="title">List of PoliceStation</span>
                            </Link>
                        </li>
                        
                           

                        {/* <li className="divider-10">
                            <Link to="/Profile">

                                <span className="icon"><i className="fa fa-id-card"></i></span>
                                <span className="title">Profile</span>
                            </Link>
                        </li> */}
                         {/* <li>
                            <a href="#">
                                <span className="icon"><i className="fa fa-sign-out" aria-hidden="true"></i></span>
                                <span className="title">Sign Out</span>
                            </a>
                        </li> */}
                        {/* <li className="divider-10">
                            <Link to="/resource">
                                <span className="icon"><i className="fa fa-building"></i></span>
                                <span className="title">Resources</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
            </>
        )
    }
}
