import React, { Component } from "react"
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import SearchLocationInput from "./SearchLocationInput";

export default class Addpolicestation extends Component {

  constructor(pops) {
    super(pops);

    this.state = {
      stationName: "",
      address: "",
      contactNo: "",
      telephoneNo: "",
      emailId: "",
      registartionNo: "",
      lat: "",
      long: "",
      stationId: "",

    }


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {

    this.setState({
      [event.target.name]: event.target.value

    });
  }

  handleSubmit(event) {

    event.preventDefault();
    // if (this.handleFormValidation()) {
    const PoliceStation = {
      ...this.state
    }


    axios.post("https://server.sps.foxberry.link/v1/policestation/insertpolicestation", PoliceStation)
      .then(res => {
        console.log("add station response", res);
        console.log("add station response", res.data);
        localStorage.setItem("StationId", res.data.stationId);

        if (res.status === 200) {

          alert('station add successfully.')

          //   statesSet.setState({
          //     changeToView: true,
          //   })
        }
      })
  }


  render() {
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />

          </div>
          <div className="container">

            <div className="card rounded shadow shadow-sm  m-4">
              <div className="card-header">
                <h3 className="mb-0 center">Add policestation</h3>

              </div>
              <div className="card-body">
                <form id="Addstation" onSubmit={this.handleSubmit}>


                  <div className="form-group">
                    <label htmlFor="registartionNo" className="control-label">Registration No</label>
                    <input type="text" id="registartionNo" name="registartionNo" className="form-control rounded-1" value={this.state.registartionNo} onChange={this.handleChange} placeholder="Enter your name" required={true} />
                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="stationName" className="control-label">Station Name</label>
                    <input type="text" id="stationName" name="stationName" className="form-control rounded-1" value={this.state.stationName} onChange={this.handleChange} placeholder="Enter your name" required={true} />

                  </div>


                  <div className="form-group mt-4" >
                    <label htmlFor="address" className="control-label">Address</label>
                    {/* <input type="text" id="address" name="address" className="form-control rounded-1" value={this.state.address} onChange={this.handleChange} placeholder="Enter your name" required={true} /> */}
                    <SearchLocationInput name="address" value={this.state.address} onchange={this.handleChange}
                      callback={(addr) => {

                        const lat = addr.geometry.location.lat();
                        const long = addr.geometry.location.lng();

                        this.setState({
                          address: addr.formatted_address,
                          full_addr: addr,
                          lat, long
                        });
                      }}
                    />
                  </div>

                  <div className="form-group mt-4" >

                    <label htmlFor=" lat" className="control-label">
                      latitude
                    </label>
                    <input
                      type="text"
                      id="lat"
                      name="lat"
                      className="form-control rounded-1"
                      value={this.state.lat}
                      onChange={this.handleChange}
                      placeholder="Enter  latitude"
                    />
                  </div>

                  <div className="form-group mt-4" >
                    <label htmlFor="long" className="control-label">longitude</label>
                    <input type="text" id="long" name="long" className="form-control rounded-1" value={this.state.long} onChange={this.handleChange} placeholder="Enter Address" required={true} />

                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="contactNo" className="control-label">Contact Number</label>
                    <input type="text" id="contactNo" name="contactNo" className="form-control rounded-1" value={this.state.contactNo} onChange={this.handleChange} placeholder="Enter your name" required={true} />

                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="telephoneNo" className="control-label">Telephone Number</label>
                    <input type="text" id="telephoneNo" name="telephoneNo" className="form-control rounded-1" value={this.state.telephoneNo} onChange={this.handleChange} placeholder="Enter your name" required={true} />

                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="emailId" className="control-label">Email Id</label>
                    <input type="text" id="emailId" name="emailId" className="form-control rounded-1" value={this.state.emailId} onChange={this.handleChange} placeholder="Enter your name" required={true} />

                  </div>




                  <div class="row justify-content-center mt-4">
                    <button type="submit" class="btn btn-default btn-primary" style={{ width: "120px", padding: "10px" }}>submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

