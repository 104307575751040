import React, { useState, useEffect, useRef } from "react";
let error;

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, propCallback) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "in" } }
  );
  autoComplete.setFields([
    "address_components",
    "geometry",
    "formatted_address",
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, propCallback)
  );
}

async function handlePlaceSelect(updateQuery, callback) {
  const addressObject = autoComplete.getPlace();
  // const lat = autoComplete.getPlace().geometry.location.lat();
  // const lng = autoComplete.getPlace().geometry.location.lng();
  const query = addressObject.formatted_address;
  console.log("--------Address object------------",addressObject);
  console.log("------------query--------------",query);
  if(query){
  updateQuery(query);
  console.log(addressObject);
    callback({
      ...addressObject,
      latitude: addressObject.geometry.location.lat(),
      longitude: addressObject.geometry.location.lng(),
    });
  }else{
    error = "Please select location";
  }
  
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBu_gLMreZwKgdL2Jf267TO96e5o-RweHw&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, props.callback)
    );
  }, []);

  return (
    <div className="search-location-input">
      <div>{error}</div>
      <input
        className="form-control"
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search for address"
        value={query}
      />
    </div>
  );
}

export default SearchLocationInput;